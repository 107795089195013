import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AddToQueue, QrCodeScanner } from '@mui/icons-material';
import { useRouter } from 'next/router';
import CustomerNav from './TopNav/CustomerNav';
import useMiddlewares from '../../core/useMiddleware';

const useStyles = makeStyles((theme) => ({
  growing: {
    flexGrow: 1,
  },
  logo: {
    marginTop: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dot: {
    color: '#F26B1D',
    fontSize: '0.60rem',
    position: 'absolute',
    top: '7px',
    right: '7px',
  },
  appBar: {
    boxShadow: 'none',
    paddingTop: '10px',
    '@media (max-width: 1130px)': {
      display: 'block',
    },
    display: 'none',
  },
}));
interface TopNavProps {
  topNavType: string;
  isLoggedIn: boolean;
}
const TopNav = ({ topNavType, isLoggedIn }: TopNavProps) => {
  const classes = useStyles();
  const router = useRouter();
  const { user } = useMiddlewares();

  const goToPosts = () => {
    if (topNavType == 'organisation') {
      router.push('/organisation/posts/create');
      return;
    }

    router.push('/posts/create');
  };

  const goToScan = () => {
    router.push('/orders/scan');
  };

  const goToHome = () => {
    router.push('/');
  };
  return (
    <AppBar position="static" color="transparent" className={classes.appBar}>
      <Toolbar style={{ paddingRight: 0 }}>
        <Typography variant="h6" noWrap>
          <img
            src="/logo.svg"
            alt="HoverIT logo"
            className={classes.logo}
            height="48"
            width="117"
            onClick={goToHome}
          />
        </Typography>
        {/* <Chip
          style={{
            alignContent: 'center',
            marginLeft: '10px',
            fontSize: '12px',
          }}
          color="primary" //#74EEDF
          label="BETA"
          size="small"
        /> */}
        <div className={classes.growing} />

        {topNavType == 'vendor' && (
          <div>
            <IconButton
              aria-label="Create new post"
              title="Create a new post"
              color="inherit"
              onClick={goToPosts}
            >
              <AddToQueue />
            </IconButton>
            <IconButton
              aria-label="Scan orders"
              title="Scan orders"
              color="inherit"
              onClick={goToScan}
            >
              <QrCodeScanner />
            </IconButton>
          </div>
        )}

        {topNavType == 'customer' && (
          <CustomerNav
            isLoggedIn={isLoggedIn}
            interestIds={user?.me?.interestIds}
          />
        )}

        {topNavType == 'organisation' && (
          <div>
            <IconButton
              aria-label="Create new post"
              title="Create a new post"
              color="inherit"
              onClick={goToPosts}
            >
              <AddToQueue />
            </IconButton>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopNav;
