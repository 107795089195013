import { useMutation, useQuery } from '@apollo/client';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  BookmarkBorderOutlined,
  Brightness1,
  ShoppingCartOutlined,
} from '@mui/icons-material';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import EventBus from '../../../core/EventBus';
import { CHECK_CART_EMPTY } from '../../../graphQL/queries/cart';
import { CHECK_WISHLIST_EMPTY } from '../../../graphQL/queries/wishlist';
import InterestsIcon from '@mui/icons-material/Interests';
import { InterestModal } from '../../home/InterestModal';
import { CREATE_PROFILE } from '../../../graphQL/mutations/user';

const useStyles = makeStyles(() => ({
  dot: {
    color: '#F26B1D',
    fontSize: '0.60rem',
    position: 'absolute',
    top: '7px',
    right: '7px',
  },
}));
interface Props {
  vendor?: boolean;
  isLoggedIn: boolean;
  interestIds: number[];
}

// function StoreFollowIcon(props) {
//   return (
//     <SvgIcon {...props}>
//       <path
//         d="M22.1965 7.59303C22.1965 7.11497 22.1263 6.70393 21.9882 6.2929C21.9882 6.22365 21.9882 6.22364 21.9179 6.15663L20.0448 1.57267C19.6983 0.614322 18.7968 0 17.687 0H4.50952C3.39969 0 2.56619 0.547305 2.21965 1.50565L0.208376 6.22364C0.208376 6.22364 0.208376 6.2929 0.208376 6.35991C0.0702135 6.77095 0 7.25124 0 7.72929C0 8.96017 0.4847 10.0548 1.38842 10.8769V17.7863C1.38842 18.8809 2.28987 19.8393 3.46991 19.8393L11.04 19.8462C12.1498 19.8462 12 19.2227 12 18.0588L19.0052 13.8462H20.6043V11.1494C21.5714 10.124 22.1965 8.89315 22.1965 7.59303ZM12 18.0588H3.46764C3.18905 18.0588 3.05089 17.8533 3.05089 17.6478V11.6967C3.46764 11.833 3.95234 11.9022 4.50725 11.9022C5.82546 11.9022 7.0055 11.3549 7.83673 10.3296C8.67024 11.2879 9.84801 11.8352 11.1662 11.8352C12.4844 11.8352 13.6645 11.2879 14.4957 10.2625C15.3292 11.2209 16.5772 11.8352 17.8954 11.8352C18.3121 11.8352 18.6587 11.766 19.0052 11.6989V13.8462L12 18.0588ZM19.1456 9.98777C18.7991 10.1933 18.3121 10.2625 17.8274 10.2625C16.8558 10.2625 15.9543 9.78448 15.4696 8.96241C15.3994 8.89316 15.3994 8.75689 15.2612 8.62062C15.1231 8.48435 14.9147 8.27883 14.498 8.27883C14.1514 8.27883 13.8728 8.4151 13.7347 8.62062C13.5965 8.75689 13.5965 8.82614 13.5263 8.96241C13.0416 9.78448 12.1379 10.2625 11.1685 10.2625C10.1968 10.2625 9.29536 9.78448 8.81066 8.96241C8.74045 8.89316 8.74045 8.75689 8.60229 8.68764C8.25575 8.2766 7.3543 8.2766 7.07571 8.68764C6.93755 8.8239 6.86734 8.96241 6.86734 9.02942C6.38264 9.8515 5.47892 10.3296 4.50952 10.3296C4.02482 10.3296 3.53786 10.2603 3.19132 10.0548C2.28987 9.57673 1.73495 8.61839 1.73495 7.59079C1.73495 7.31602 1.80517 6.97424 1.87312 6.7017V6.63468L3.88439 1.98147C3.95461 1.8452 4.02255 1.50341 4.64768 1.50341H17.8274C18.106 1.50341 18.5205 1.57267 18.6609 1.98147L20.5341 6.56543V6.63468C20.6043 6.90945 20.6722 7.25124 20.6722 7.52377C20.602 8.54913 20.0471 9.50748 19.1456 9.98777Z"
//         fill="black"
//       />

//       <path
//         d="M18.2399 15.4593C17.1091 14.5059 15.3701 14.5405 14.2835 15.5631C13.1588 16.6214 13.1588 18.3374 14.2835 19.3958L17.5611 22.4801C17.936 22.8329 18.5439 22.8329 18.9188 22.4801L22.1964 19.3958C23.3211 18.3374 23.3211 16.6214 22.1964 15.5631C21.1097 14.5405 19.3707 14.5059 18.2399 15.4593ZM17.6776 16.2018L17.9005 16.4116C18.088 16.588 18.3919 16.588 18.5794 16.4116L18.8023 16.2018C19.5521 15.4963 20.7678 15.4963 21.5176 16.2018C22.2674 16.9074 22.2674 18.0514 21.5176 18.757L18.2399 21.8413L14.9623 18.757C14.2125 18.0514 14.2125 16.9074 14.9623 16.2018C15.7121 15.4963 16.9278 15.4963 17.6776 16.2018Z"
//         fill="currentColor"
//         stroke="black"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//     </SvgIcon>
//   );
// }

const CustomerNav = ({ isLoggedIn, interestIds }: Props) => {
  const classes = useStyles();
  const router = useRouter();
  const [activeWishList, setActiveWishList] = useState(true);
  const [activeCart, setActiveCart] = useState(true);
  const [showInterestModal, setShowInterestModal] = useState<boolean>(false);

  const { data: wishEmpty, refetch } = useQuery(CHECK_WISHLIST_EMPTY, {
    fetchPolicy: 'no-cache',
  });
  const [createProfile] = useMutation(CREATE_PROFILE);

  const { data: cartEmpty } = useQuery(CHECK_CART_EMPTY, {
    fetchPolicy: 'no-cache',
  });

  const goToWishlist = () => {
    router.push('/wishlist');
  };
  // const goToFollowPage = () => {
  //   router.push('/follower');
  // };
  const goToCart = () => {
    router.push('/cart');
  };
  useEffect(() => {
    if (cartEmpty) {
      setActiveCart(cartEmpty.checkIfCartIsEmpty.status);
    }
    if (wishEmpty) {
      setActiveWishList(wishEmpty.checkIfWishlistIsEmpty.status);
    }
  }, [wishEmpty, cartEmpty]);

  EventBus.on('cartIsEmpty', (status) => {
    setActiveCart(status);
  });
  EventBus.on('WishListRemoved', (_status) => {
    refetch();
  });
  EventBus.on('wishListIsEmpty', (status) => {
    setActiveWishList(status);
  });

  const showInterestPopup = () => {
    setShowInterestModal(true);
  };
  return (
    <div>
      {/* <IconButton
        aria-label="Followed Stores"
        title="Followed Stores"
        color="inherit"
        onClick={goToFollowPage}
      >
        <StoreFollowIcon />
      </IconButton> */}
      <IconButton
        aria-label="Your Interests"
        title="Your Interests"
        color="inherit"
        onClick={showInterestPopup}
      >
        <InterestsIcon />
      </IconButton>
      <IconButton
        aria-label="Wishlist"
        title="Wishlist"
        color="inherit"
        onClick={goToWishlist}
      >
        <BookmarkBorderOutlined />
        {!activeWishList && isLoggedIn && (
          <Brightness1 className={classes.dot} />
        )}
      </IconButton>
      <IconButton
        aria-label="Cart"
        title="Cart"
        color="inherit"
        onClick={goToCart}
      >
        <ShoppingCartOutlined />
        {!activeCart && isLoggedIn && <Brightness1 className={classes.dot} />}
      </IconButton>
      {showInterestModal && (
        <InterestModal
          userInterestIds={interestIds}
          onSave={(selectedInterests) => {
            const selectedInterestIds = selectedInterests.map(
              (selectedInterest) => selectedInterest.id,
            );

            if (isLoggedIn) {
              if (selectedInterestIds.length > 0) {
                createProfile({
                  variables: {
                    data: {
                      interestIds: selectedInterestIds,
                    },
                  },
                }).then((_res: any) => {
                  router.reload();
                });
              } else {
                router.reload();
              }
            } else {
              if (selectedInterestIds.length > 0) {
                localStorage.setItem(
                  'interestIds',
                  JSON.stringify(selectedInterestIds),
                );
              }
              router.reload();
            }
          }}
        />
      )}
    </div>
  );
};
CustomerNav.propTypes = {
  vendor: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
};
export default CustomerNav;
